<template>
  <div class="mb-2">
    <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        addButtTitle="Add condition"
        linePrefix="conditionList">
    </lineFieldsArray>
    <input type="hidden" name="conditionList" :value="JSON.stringify(pairArray)"/>
  </div>
</template>
<script>
import lineFieldsArray from '@/commonComponents/lineFieldsArray.vue';

export default {
  components: {
    lineFieldsArray
  },
  data() {
    let measurementFields = [];
    // noinspection JSUnresolvedVariable
    const measurement = this.result.valueLine.measurement;
    const measurements = this.result['additional']['measurements'];
    if (measurement && measurement in measurements) {
      measurementFields = measurements[measurement].fields;
    }
    console.log();

    return {
      measurementFields: measurementFields
    };
  },
  props: ["result", "field", "fieldAttributes", "value", "isValid"],
  watch: {
    'result.valueLine.measurement': function (newVal) {
      const measurement = this.result['additional']['measurements'][newVal];
      if (measurement) {
        this.measurementFields = measurement.fields;
        this.$emit('input', []);
      }
    }
  },
  computed: {
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value != 'undefined' && this.value !== '') {
          pairArray = this.value;
        }
        return pairArray;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    fields() {
      return {
        field: {
          type: 'select',
          name: 'Field',
          associatedOption: this.measurementFields,
          fieldAttrInput: {class: 'required'},
        },
        operator: {
          type: 'select',
          name: 'Operator',
          associatedOption: ['==', '!=', '<', '>', '<=', '>=',],
          fieldAttrInput: {class: 'required'},
        },
        value: {
          type: 'number',
          name: 'Value',
          associatedOption: this.result['additional']['permissionsGroups'],
          fieldAttrInput: {class: 'required'},
        },
        // unit: {
        //   type: 'select',
        //   name: 'Unit',
        //   associatedOption: ['default', 'ms', 'ns'],
        //   fieldAttrInput: {class: 'required'},
        // },
        remove: {
          inputFieldFormatter: 'trash',
          colAttrs: {
            style: 'max-width: 23px;min-width: 35px;',
            class: 'mt-1',
          },
        },
      };
    },
  },
  methods: {
    checkInput(value) {
      this.pairArray = [...value];
    },
  },
}
;
</script>